import React from 'react';
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Seo from '../components/SEO';
import Layout from '../components/Layout';
import HeroBanner from '../components/HeroBanner';
import Promotion from '../components/Promotion';
import Horaire from '../components/Horaires';
import VigneronSlider from '../components/VigneronSlider';

const IndexPage = ( {data} ) => {

  const bannerData = data.pageElements.edges[0].node.frontmatter

  const Promotions = data.promotions.edges
  .map((promo) => Date.parse(promo.node.frontmatter.endDate) > Date.now() &&
  <Promotion  titre={promo.node.frontmatter.title} 
              key={promo.node.frontmatter.title} 
              description={promo.node.frontmatter.description}
              image={promo.node.frontmatter.image} />)


  const description = data.description.edges[0].node.frontmatter.description



// Permet de checker si une promo est dépassée
  function checkFalse(val) {
    return val === false
  }

  //randomise l'ordre des vignerons au chargement de la page
  const vignerons = data.vignerons.edges.sort( () => .5 - Math.random() )
  const imgFond = getImage(data.hpImage)
  return(
    <Layout>
      <Seo title="Accueil"/>
      <HeroBanner bannerImg={bannerData.image} 
                  subtitle={bannerData.subtitle}
                  textbutton={bannerData.textbutton}
                  buttonurl={bannerData.buttonurl} />
      <div className="grey-bg">
        <div className="main-container">
          {/* <h2 className="section-title">Promotions</h2> */}
          {/* // Change le message si aucune promo actuelle*/}
          {/* {Promotions.every(checkFalse) ? <p>Il n'y a pas de promotions pour le moment</p> : Promotions} */}

          <h2 className="section-title">Horaires</h2>
          <Horaire />
        </div>
      </div>

      <div className="main-container">
        <h2 className="section-title">Le vignoble</h2>
        <div className="vignoble">
          <p>{description}</p>

          <div className="video">
            <iframe width="445" height="250" src="https://www.youtube-nocookie.com/embed/KDkiDSKVVJg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
        

        <div className="main-container">
          <h2 className="section-title">Vignerons</h2>
        </div>
        <VigneronSlider sliderData={vignerons}/>
      </div>
      <GatsbyImage image={imgFond} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
{
  promotions: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/promotions/"}}) {
    edges {
      node {
        frontmatter {
          title
          description
          endDate
          image {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  },

  vignerons: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/vignerons/"}}) {
    edges {
      node {
        frontmatter {
          slug
          name
          numero
          mail
          site
          blancs
          rouges
          imgBouteille {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  },

  pageElements: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/banner.md/"}}) {
    edges {
      node {
        frontmatter {
          title
          subtitle
          textbutton
          buttonurl
          image {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  },

  description: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/description-vignoble.md/"}}) {
    edges {
      node {
        frontmatter {
          title
          description
        }
      }
    }
  }

  hpImage: file(relativePath: {eq: "fond-hp.jpg"}) {
    childImageSharp {
			gatsbyImageData(
				placeholder: BLURRED
      )
    }
  }
}
`

