import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Promotion = ({ titre, description, image }) => {
    const promoImg = getImage(image);
    console.log(image);
    return(
        <div className="promotion">
            <div className="promo-content">
                <h3>{titre}</h3>
                <p>{description}</p>
            </div>
            <div className="promo-img">
                <GatsbyImage image={promoImg} alt={titre}/>
            </div>
        </div>
    )
}

export default Promotion;
