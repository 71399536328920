import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const VigneronHpCard = ({ nom, numéro, mail, site, blancs, rouges, imgBouteille }) => {
    const blancsRemanies = blancs.split(';').slice(0, 5).join(', ');
    const rougesRemanies = rouges.split(';').slice(0, 5).join(', ');

    const imageBouteille = getImage(imgBouteille);
    

    return(
        <div className="vigneron-hp-card">
            <div className="flex-content">
                <div className="meta-infos">
                    <h3>{ nom }</h3>
                    <ul>
                        <li>{ numéro }</li>
                        <li>
                            <a href={`mailto:${mail}`}> { mail }</a>
                        </li>
                        <li>
                            <a href={site}>{ site }</a>
                        </li>
                    </ul>

                    <p>{`${blancsRemanies}..., ${rougesRemanies}...`}</p>
                </div>
                <div className="img-bouteille">
                    {imgBouteille && <GatsbyImage image={imageBouteille} />}
                </div>
            </div>
        </div>
    )
}

export default VigneronHpCard;