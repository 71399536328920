import React from 'react';

import BackgroundSection from './GbiBridged';

const HeroBanner = ({ bannerImg, subtitle, textbutton, buttonurl }) => {
    return(
        <div>
            <BackgroundSection  className="hero-banner" 
                                img={bannerImg}
                                subtitle={subtitle}
                                textbutton={textbutton}
                                buttonurl={buttonurl} />
        </div>
    )
}

export default HeroBanner;