import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

    const BackgroundSection = ({ className, img, buttonurl, textbutton, subtitle }) => {
        // Set ImageData.
        return (
          <BackgroundImage
            Tag="section"
            className={className}
            fluid={img.childImageSharp.fluid}
          >
              <div>
                    <h1>Caveau de Saillon</h1>
                    <p>{subtitle}</p>
                    <a href={buttonurl}>
                      <button>{textbutton}</button>
                    </a>
              </div>
            
          </BackgroundImage>
        )
      }
      
export default BackgroundSection; 