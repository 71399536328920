import React, { useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { useSwipeable } from "react-swipeable";
import VigneronHpCard from "./VigneronHpCard";

import { MdKeyboardArrowLeft, MdKeyboardArrowRight  } from 'react-icons/md'
import { BsDot } from 'react-icons/bs'

const VigneronSlider = ({ sliderData }) => {
    // récupération des données pour les composants enfants
    // const data = useStaticQuery(graphql`
    // {
    //   vignerons: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/vignerons/"}}) {
    //     edges {
    //       node {
    //         frontmatter {
    //           slug
    //           name
    //           numero
    //           mail
    //           site
    //           blancs
    //           rouges
    //           imgBouteille
    //         }
    //       }
    //     }
    //   }
    // }
    // `)

    // Hooks pour connaître l'image actuellement display; 
    const length = sliderData.length
    const [current, setCurrent] = useState(0)

    //randomise l'accès aux vignerons mais re-render à chaque fois
    // const [shuffledVignerons, setVignerons] = useState(data.vignerons.edges.sort( () => .5 - Math.random() ))

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1)
    }

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1)
    }
    
    // pour le rendre swipeable en vue mobile
    const handlers = useSwipeable({
        onSwipedLeft: () => setCurrent(current === length - 1 ? 0 : current + 1),
        onSwipedRight: () => setCurrent(current === 0 ? length - 1 : current - 1),
    })


    
    const vignerons = sliderData.map((vigneron, index) => {
      let i = vigneron.node.frontmatter;
          return(
              <div className={index === current ? 'slide-active' : 'slide'} key={index}>
              {index === current && (
                <Link to={i.slug}>
                  <VigneronHpCard key={i.name} 
                  nom={i.name} 
                  numéro={i.numero}
                  mail={i.mail}
                  site={i.site}
                  blancs={i.blancs}
                  rouges={i.rouges}
                  imgBouteille={i.imgBouteille} /></Link>
              )}    
              </div>
              )
          })

          const vigneronsDots = sliderData.map((vigneron, index) => <li key={index} className={current === index ? 'slider-button-active' : 'slider-button' }><BsDot /></li>)

    return (
      <section className="slider"
                {...handlers}>
                  {vignerons}
        <ul className="slider-buttons">
            <button onClick={prevSlide}><MdKeyboardArrowLeft /></button>
                {vigneronsDots}
            <button onClick={nextSlide}><MdKeyboardArrowRight /></button>
        </ul>
      </section>
    );
  }

export default VigneronSlider;