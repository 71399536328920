import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const Horaire = ({ horaires }) => (

        <div className="horaires">
            {horaires.map((el, index) => {
                if(index === horaires.length - 1) {
                    return(
                    <div>
                        <p className="jour">{el.Horaire.jours_semaine}</p>
                        <p className="heure">{el.Horaire.heures}</p>
                    </div>)
                } else {
                    return(
                    <div className="horaire-tb">
                        <p className="jour">{el.Horaire.jours_semaine}</p>
                        <p className="heure">{el.Horaire.heures}</p>
                    </div>)
                }
            })}
        </div>
)

export default function MyHoraire(props) {
    return (
        <StaticQuery 
            query={graphql`
                query {
                    markdownRemark(fileAbsolutePath: {regex: "/horaires.md/"}) {
                        frontmatter {
                          horaires {
                            Horaire {
                              heures
                              jours_semaine
                            }
                          }
                        }
                    }
                }
            `}
            render={data => <Horaire horaires={data.markdownRemark.frontmatter.horaires} {...props} />}
            />
    )
}